<template>
	<v-container v-if="$can('view', 'Page')" fluid>
		<v-row>
			<v-col cols="3">
				<SidebarFilters
					:filter-name="filters"
					title="Home Page"
				></SidebarFilters>
			</v-col>

			<v-col cols="9">
				<v-card>
					<transition name="fade" mode="out-in">
						<router-view></router-view>
					</transition>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SidebarFilters from '@/components/SidebarFilters'

export default {
	components: {
		SidebarFilters,
	},
	data() {
		return {
			filters: [
				{ name: 'Manage Content', path: 'edit' },
				{ name: 'Manage Sections', path: 'shuffle' },
			],
		}
	},
}
</script>
